import React from "react"
import styled from "styled-components"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import Fade from "react-reveal/Fade"
import GLink from "../../../gatsby/GLink"
import { skyBlue, white, lightBlack, grey, lightGrey } from "../../constants/colors"
import { largeDesktop, bigDesktop, desktop, smallMobile } from "../../constants/screenSizes"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import Block from "../../components/landing-page/Block"
import InnerBlock from "../../components/landing-page/InnerBlock"
import Part from "../../components/landing-page/Part"
import Subheading from "../../components/landing-page/Subheading"
import Description from "../../components/landing-page/Description"
import Title from "../../components/landing-page/Title"
import TrustedBlock from "../../components/landing-page/TrustedBlock"
import LargeTitle from "../../components/landing-page/LargeTitle"
import LPRecommendationsBlock from "../../components/landing-page/new/components/reviews/LPRecommendationsBlock"
import IllustrationRipple from "../../../svg/illustration-ripple-white.inline.svg"
import ImageBrandedMerch from "../../../images/branded-merch.png"
import ImageChristmasHampers from "../../../images/image-christmas-hampers.jpg"
import ImageTeamGifting from "../../../images/image-team-gifting.jpg"
import ImageReturnToWorkplace from "../../../images/image-return-to-workplace.jpg"
import IconEcoConscious from "../../../svg/icon-eco-conscious.inline.svg"
import IconEconomicEmpowerment from "../../../svg/icon-economic-empowerment.inline.svg"
import IconCharitableContributions from "../../../svg/icon-charitable-contributions.inline.svg"
import IconFastDelivery from "../../../svg/icon-fast-delivery.inline.svg"
import IconCarbonNeutralBlack from "../../../svg/icon-carbon-neutral-black.inline.svg"
import IconQualityChecks from "../../../svg/icon-quality-checks.inline.svg"
import IconRecycledMaterials from "../../../svg/icon-recycled-materials.inline.svg"
import IconSustainablePackaging from "../../../svg/icon-sustainable-packaging.inline.svg"
import IconDissolvablePackaging from "../../../svg/icon-dissolvable-packaging-black.inline.svg"
import IconMinimiseWaste from "../../../svg/icon-minimise-waste.inline.svg"
import IconVegetableInks from "../../../svg/icon-vegetable-inks.inline.svg"
import CorporateQuotes from "../../components/page/corporate/corporate-quotes/CorporateQuotes"
import EnquiryForm from "../../components/landing-page/new/components/lead-capture/enquiry-form/EnquiryForm"

const MastheadBlock = styled(Block)`
  @media (max-width: ${largeDesktop}px) {
    padding-bottom: 150px;
  }

  @media (max-width: ${bigDesktop}px) {
    padding-bottom: 173px;
  }

  @media (max-width: ${smallMobile}px) {
    padding-bottom: 275px;
  }
`

const MastheadTitle = styled(Title)`
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 1.44px;
  margin: 18px 0 20px;

  @media (max-width: ${desktop}px) {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 1.08px;
  }

  @media (max-width: ${smallMobile}px) {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.96px;
  }
`

const StyledSpan = styled.span`
  font-weight: bold;
`

const MastheadDescription = styled(Description)`
  @media (max-width: ${desktop}px) {
    width: 100%;
    max-width: 540px;
  }

  @media (max-width: 720px) {
    max-width: 400px;
  }

  @media (max-width: 620px) {
    max-width: 300px;
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 333px;
  }
`

const IllustrationRippleContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 457px;
  @media (max-width: ${bigDesktop}px) {
    height: 365px;
  }

  @media (max-width: ${desktop}px) {
    height: 303px;
  }

  @media (max-width: ${smallMobile}px) {
    height: 332px;
  }
`

const IllustrationBackground = styled(IllustrationRipple)`
  width: 556px;
  height: 457px;
  @media (max-width: ${bigDesktop}px) {
    width: 444px;
    height: 365px;
  }

  @media (max-width: ${desktop}px) {
    width: 368px;
    height: 303px;
  }

  @media (max-width: ${smallMobile}px) {
    width: 420px;
    height: 348px;
  }
`

const OfferPart = styled(Part)`
  width: 48.5%;
  margin-top: 36px;
  position: relative;
  @media (max-width: ${desktop}px) {
    width: 48%;
  }

  @media (max-width: ${smallMobile}px) {
    width: 100%;
  }
`

const OfferImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const OfferTitle = styled(Title)`
  position: absolute;
  bottom: 40px;
  left: 40px;

  @media (max-width: ${desktop}px) {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1.05px;
    bottom: 20px;
    left: 20px;
  }

  @media (max-width: ${smallMobile}px) {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.9px;
    bottom: 30px;
    left: 30px;
  }
`

const ImpactGrid = styled.div`
  width: 100%;
  margin: 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 40px;

  @media (max-width: ${desktop}px) {
    margin: 80px 0 70px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 80px;
  }

  @media (max-width: ${smallMobile}px) {
    margin: 60px 0;
    row-gap: 60px;
  }
`

const ImpactGridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`

const ImpactPart = styled(Part)`
  border-top: 1px solid ${lightGrey};
`

const ImpactDescription = styled(Description)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.7px;
`

const StyledBlock = styled.div<{ margin?: string; tabletMargin?: string; mobileMargin?: string }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${({ margin }) => margin || 0};

  @media (max-width: ${desktop}px) {
    margin: ${({ tabletMargin }) => tabletMargin || 0};
  }

  @media (max-width: ${smallMobile}px) {
    margin: ${({ mobileMargin }) => mobileMargin || 0};
  }
`

const LikeToKnowItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${smallMobile}px) {
    flex-direction: column;
  }
`

const LikeToKnowImageContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 132px;
`

const LikeToKnowDescription = styled(Description)`
  max-width: 380px;
  margin-left: 30px;

  @media (max-width: ${desktop}px) {
    max-width: unset;
  }

  @media (max-width: ${smallMobile}px) {
    margin: 20px 0 0;
  }
`

const InternalLink = styled(GLink)`
  color: ${lightBlack};
  text-decoration: underline;
  &:visited {
    color: ${lightBlack};
  }
`

const ExternalLink = styled(InternalLink).attrs({ as: "a" })``

const EnquiryFormContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: ${desktop}px) {
    max-width: 630px;
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 320px;
  }
`

const EnquiryFormTextContainer = styled.div`
  margin-bottom: 40px;

  @media (max-width: ${desktop}px) {
    margin-bottom: 35px;
  }

  @media (max-width: ${smallMobile}px) {
    margin-bottom: 20px;
  }
`

const offerItems = [
  {
    url: "/sustainable-branded-merchandise",
    src: ImageBrandedMerch,
    alt: "Branded Merch",
    title: "Branded Merchandise",
  },
  {
    url: "/corporate-hampers-gift-boxes",
    src: ImageChristmasHampers,
    alt: "Coproate Hampers",
    title: "Corporate Hampers & Gift Boxes",
  },
  {
    url: "/team-gifting-platform",
    src: ImageTeamGifting,
    alt: "Team Gifting Platform image",
    title: "Team Gifting Platform",
  },
  {
    url: "/office-supplies",
    src: ImageReturnToWorkplace,
    alt: "Office Supplies image",
    title: "Office Supplies",
  },
]

const impactItems = [
  {
    SVG: IconEcoConscious,
    title: "170 slices",
    description: "of bread saved from landfill*",
  },
  {
    SVG: IconEconomicEmpowerment,
    title: "3 hours 20 minutes’",
    description: "employment for refugees**",
  },
  {
    SVG: IconCharitableContributions,
    title: "£21 donated",
    description: "to sea conservation charities***",
  },
]

const impactDescriptions = [
  "*Based on 100 sales of The Craft Beer Collection Gift Box",
  "**Based on 100 sales of The Ethical Afternoon Tea Gift Box",
  "***Based on 100 sales of The Red Wine & Treats Gift Box",
]

const experienceItems = [
  {
    SVG: IconFastDelivery,
    description: `We work closely with some of the largest and most experienced fulfilment and
                  delivery partners to ensure a seamless experience.`,
  },
  {
    SVG: IconCarbonNeutralBlack,
    description: `We use carbon neutral delivery methods where possible and are now introducing a
                  zero-emissions option for London deliveries. Every year we offset our carbon in
                  an effort to tread more lightly on this earth. On top of this, our photography
                  studio plants trees for every photoshoot we book with them.`,
  },
  {
    SVG: IconQualityChecks,
    description: `We regularly run quality checks to ensure high quality output with every order
                  – and happy recipients for your gifts or supplies. With a dedicated customer
                  service team we are on hand to support with any issues related to deliveries.`,
  },
]

const sustainabilityItems = [
  {
    SVG: IconRecycledMaterials,
    description: `Our boxes and other packaging is made from majority recycled materials and are
                  recyclable. We also encourage people to reuse the boxes and give them a second
                  life.`,
  },
  {
    SVG: IconSustainablePackaging,
    description: `When you choose a wicker basket, rest assured that ours are from suppliers who
                  prioritise sustainable farming methods. Find out more here.`,
  },
  {
    SVG: IconDissolvablePackaging,
    description: `In packaging your order, we cushion contents using a fully biodegradable fill
                  including packing peanuts made from potato starch, so they can go straight into
                  the compost or run under the tap.`,
  },
  {
    SVG: IconMinimiseWaste,
    description: `We always aim to minimise plastic waste throughout oru packaging and products.
                  We work with brands who see this as a priority too.`,
  },
  {
    SVG: IconVegetableInks,
    description: `We use vegetable inks for our printing, which is more eco-friendly than
                  alternative, traditional inks.`,
  },
]

const privacyItem = {
  title: "We’re serious about privacy",
  description: (
    <>
      We take the security of personal data extremely seriously. It’s why we created our{" "}
      <InternalLink to="/team-gifting-platform">Team Gifting Platform</InternalLink> to avoid the
      need to share personal employee details over email. Regardless, should you need to share
      proprietary information, we password-protect all files as standard. You can view{" "}
      <InternalLink to="/privacy-and-cookie-policy">our website’s privacy policy here</InternalLink>{" "}
      and our partners have their own respective GDPR and privacy policies too – see{" "}
      <ExternalLink href="https://www.spicersofhythe.co.uk/policies/privacy-policy" target="_blank">
        Spicers of Hythe’s
      </ExternalLink>
      ,{" "}
      <ExternalLink
        href="https://www.dhl.com/gb-en/home/our-divisions/parcel/business-users/about/privacy-notice.html"
        target="_blank"
      >
        DHL’s
      </ExternalLink>{" "}
      and{" "}
      <ExternalLink href="https://www.dpd.co.uk/privacy_policy.jsp" target="_blank">
        DPD’s
      </ExternalLink>
      .
    </>
  ),
}

interface Props {}

const CorporatePage: React.FC<Props> = (): JSX.Element => {
  return (
    <Layout fullWidth>
      <SEO
        title="Social Supermarket Corporate Offering"
        description="Sustainable and ethical corporate gifting, branded merchandise and office supplies."
      />
      <ColorWrapper background={skyBlue}>
        <Fade bottom>
          <MastheadBlock>
            <InnerBlock>
              <Part width="600px">
                <Subheading color={white} isBordered borderColor={white}>
                  Our corporate products & services
                </Subheading>
                <MastheadTitle color={white}>
                  <StyledSpan>Create social impact</StyledSpan> with every purchase
                </MastheadTitle>
                <MastheadDescription color={white}>
                  Whether you’re looking for gifts that give back or create impact with your
                  procurement and events, we are here to help connect business with purpose-driven
                  products.
                </MastheadDescription>
              </Part>
            </InnerBlock>
          </MastheadBlock>
        </Fade>
        <IllustrationRippleContainer>
          <IllustrationBackground />
        </IllustrationRippleContainer>
      </ColorWrapper>
      <Fade>
        <Block>
          <InnerBlock>
            <Part width="100%">
              <Subheading color={lightBlack} isBordered borderColor={skyBlue}>
                What do we offer?
              </Subheading>
            </Part>
          </InnerBlock>
          {offerItems.map(({ url, src, alt, title }) => (
            <OfferPart key={title}>
              <GLink to={url}>
                <OfferImage src={src} alt={alt} />
                <OfferTitle color={white}>{title}</OfferTitle>
              </GLink>
            </OfferPart>
          ))}
        </Block>
      </Fade>
      <Fade>
        <TrustedBlock />
      </Fade>
      <Fade>
        <Block>
          <Part width="650px" padding="0 0 80px 60px" wrapPadding="0 0 60px 0">
            <Title>
              Here's what <b>our customers</b> have to say
            </Title>
          </Part>
          <CorporateQuotes />
        </Block>
      </Fade>
      <LPRecommendationsBlock title="As Seen in" backgroundColor={grey} />
      <Fade>
        <Block>
          <InnerBlock>
            <Part width="100%" padding="0 0 25px" wrapPadding="0 0 20px">
              <Subheading color={lightBlack} isBordered borderColor={skyBlue}>
                Measure your impact
              </Subheading>
            </Part>
            <Part width="725px">
              <Title>
                See the <StyledSpan>impact that you create</StyledSpan> through your company’s
                purchases.
              </Title>
              <Description margin="20px 0 0">
                We make it easy to see at a glance the good you’re contributing to. We can provide
                your own impact statistics from your order, for example:
              </Description>
            </Part>
          </InnerBlock>
          <ImpactGrid>
            {impactItems.map(({ SVG, title, description }) => (
              <ImpactGridItem key={title}>
                <SVG />
                <LargeTitle color={skyBlue} margin="20px 0 5px">
                  {title}
                </LargeTitle>
                <Description>{description}</Description>
              </ImpactGridItem>
            ))}
          </ImpactGrid>
          <InnerBlock>
            <ImpactPart width="560px" padding="30px 0 0" wrapPadding="30px 0 0">
              {impactDescriptions.map(description => (
                <ImpactDescription key={description}>{description}</ImpactDescription>
              ))}
            </ImpactPart>
          </InnerBlock>
        </Block>
      </Fade>
      <Fade>
        <Block>
          <InnerBlock>
            <Subheading color={lightBlack} isBordered borderColor={skyBlue}>
              You might like to know
            </Subheading>
          </InnerBlock>
          <StyledBlock margin="25px 0 0" tabletMargin="20px 0 0" mobileMargin="25px 0 0">
            <InnerBlock>
              <Part width="100%" padding="0 0 60px" wrapPadding="0 0 60px">
                <Title>Fulfilment with a tailored experience</Title>
              </Part>
            </InnerBlock>
            {experienceItems.map(({ SVG, description }) => (
              <Part width="46%" padding="0 0 15px" wrapPadding="0 0 60px" key={description}>
                <LikeToKnowItemContainer>
                  <LikeToKnowImageContainer>
                    <SVG />
                  </LikeToKnowImageContainer>
                  <LikeToKnowDescription>{description}</LikeToKnowDescription>
                </LikeToKnowItemContainer>
              </Part>
            ))}
          </StyledBlock>
          <StyledBlock margin="85px 0 0" tabletMargin="20px 0 0" mobileMargin="100px 0 0">
            <InnerBlock>
              <Part width="100%" padding="0 0 60px" wrapPadding="0 0 60px">
                <Title>Sustainability is at the heart of everything we do</Title>
              </Part>
            </InnerBlock>
            {sustainabilityItems.map(({ SVG, description }) => (
              <Part width="46%" padding="0 0 100px" wrapPadding="0 0 60px" key={description}>
                <LikeToKnowItemContainer>
                  <LikeToKnowImageContainer>
                    <SVG />
                  </LikeToKnowImageContainer>
                  <LikeToKnowDescription>{description}</LikeToKnowDescription>
                </LikeToKnowItemContainer>
              </Part>
            ))}
          </StyledBlock>
          <StyledBlock tabletMargin="20px 0 0" mobileMargin="100px 0 0">
            <InnerBlock>
              <Part width="725px">
                <Title>{privacyItem.title}</Title>
                <Description margin="20px 0 0">{privacyItem.description}</Description>
              </Part>
            </InnerBlock>
          </StyledBlock>
        </Block>
      </Fade>
      <div id={"enquiry-form"}>
        <ColorWrapper background={grey}>
          <Fade>
            <Block>
              <EnquiryFormContainer>
                <EnquiryFormTextContainer>
                  <LargeTitle>Get in touch.</LargeTitle>
                  <Description margin="10px 0 0">
                    For general corporate enquiries, please get in touch using the form below:
                  </Description>
                </EnquiryFormTextContainer>
                <EnquiryForm source={"Inbound Enquiry - General"} name={"GeneralEnquiryForm"} />
              </EnquiryFormContainer>
            </Block>
          </Fade>
        </ColorWrapper>
      </div>
    </Layout>
  )
}

export default CorporatePage
